<script>
    import arrowLeft from "$assets/icon-arrow-left.svg";
    import arrowRight from "$assets/icon-arrow-right.svg";
    import { page } from "$lib/page.store";

    export let pages;
</script>

{#if pages > 1}
<div class="pagination">
  <button class="left" on:click={(e) => ($page > 0 ? $page-- : null)} disabled={$page === 0}>
    <img src={arrowLeft} alt="Arrow left" />
  </button>
  <button class="right" on:click={(e) => ($page + 1 < pages ? $page++ : null)} disabled={$page === pages - 1}>
    <img src={arrowRight} alt="Arrow right" />
  </button>
</div>
{/if}