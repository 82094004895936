import { init, addMessages } from "svelte-i18n";
import en_US from "$locales/en_US.json";
import it_IT from "$locales/it_IT.json";

const defaultLocale = "en_US";
addMessages("en_US", en_US);
addMessages("it_IT", it_IT);
init({
  fallbackLocale: defaultLocale,
  initialLocale: defaultLocale,
});
