<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import Button from "$lib/components/Button.svelte";

  export let params = {};
  export let categories = [];

  let category = null, videos = [];
  $: if (params.slug) {
    category = categories.find((category) => category.slug === params.slug);
    if (category) {
      videos = category.videos;
    }
  }

  onMount(() => {
    document.body.classList.remove("body--hide-nav");
    document.body.classList.add("body--inverted");
  });
</script>

<div class="content content--category">
  <ul>
    {#each videos as video}
      <li><a href={`#/${params.lang}/categories/${params.slug}/${video}`}>{video}</a></li>
    {/each}
  </ul>
</div>
<Button variant="back" />
