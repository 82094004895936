<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import Button from "$lib/components/Button.svelte";
  import Pagination from "$lib/components/Pagination.svelte";
  import { page } from "$lib/page.store";

  export let params = {};
  export let videos = 0;

  const itemsPerPage = 18;
  let pages = 0;
  let videoList = [];

  $: {
    videoList = [...Array(videos).keys()].slice(
      $page * itemsPerPage,
      $page * itemsPerPage + itemsPerPage
    );
    pages = Math.ceil(videos / itemsPerPage);
  }

  onMount(() => {
    document.body.classList.remove("body--hide-nav");
    document.body.classList.add("body--inverted");
  });
</script>

<div class="content content--sample-list">
  <ul>
    {#each videoList as video}
      <li><a href={`#/${params.lang}/samples/${video + 1}`}>{video + 1}</a></li>
    {/each}
  </ul>
</div>
<Button variant="home" />

<Pagination {pages} />
