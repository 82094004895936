<script>
  import home from "$assets/icon-home.svg";
  import back from "$assets/icon-back.svg";

  export let variant;

  const handleClick = () => {
    switch (variant) {
      case "home":
        window.location.href = "#/en_US";
        break;
      case "back":
        window.history.back();
        break;
      default:
        window.location.href = `#/${variant}`;
        break;
    }
  };
</script>

<button class={variant} on:click={handleClick}>
  {#if variant === "home"}
    <img src={home} alt="Home" />
  {:else if variant === "back"}
    <img src={back} alt="Back" />
  {/if}
</button>
