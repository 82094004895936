<script>
  import { onMount } from "svelte";
  import { _, locale } from "svelte-i18n";
  import Button from "$lib/components/Button.svelte";
  import { page } from "$lib/page.store";

  export let params = {};

  $: if (params && params.lang) {
    locale.set(params.lang);
  }

  onMount(() => {
    $page = 0;
    document.body.classList.add("body--hide-nav");
    document.body.classList.remove("body--inverted");
  })
</script>

<div class="content content--lang">
  <h1>{$_("choose_part")}</h1>
  <ul>
    <li><a href={`#/${params.lang}/samples`}>{$_("sample_number")}</a></li>
    <li><a href={`#/${params.lang}/categories`}>{$_("category")}</a></li>
  </ul>
</div>

{#if false}
<Button variant="home" />
{/if}