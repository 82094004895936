<script>
  import { onMount } from "svelte";
  import { _ } from "svelte-i18n";
  import Button from "$lib/components/Button.svelte";

  export let params = {};
  export let categories = [];

  onMount(() => {
    document.body.classList.remove("body--hide-nav");
    document.body.classList.add("body--inverted");
  });
</script>

<div class="content content--category-list">
  <ul>
    {#each categories as category}
      <li><a href={`#/${params.lang}/categories/${category["slug"]}`}>{$_(`industries.${category["slug"]}`)}</a></li>
    {/each}
  </ul>
</div>
<Button variant="home" />
