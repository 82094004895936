import { wrap } from 'svelte-spa-router/wrap';

import Home from '$routes/Home.svelte';
import NotFound from '$routes/NotFound.svelte';

import Language from '$routes/lang/Language.svelte';
import CategoryList from '$routes/lang/category/CategoryList.svelte';
import Category from '$routes/lang/category/Category.svelte';
import SampleList from '$routes/lang/sample/SampleList.svelte';
import Sample from '$routes/lang/sample/Sample.svelte';

import config from "config";

export default {
    '/': wrap({
        component: Home,
        props: { languages: config.languages },
    }),
    '/:lang': Language,
    '/:lang/categories': wrap({
        component: CategoryList,
        props: { categories: config.categories },
    }),
    '/:lang/categories/:slug': wrap({
        component: Category,
        props: { categories: config.categories },
    }),
    '/:lang/categories/:slug/:id': Sample,
    '/:lang/samples': wrap({
        component: SampleList,
        props: { videos: config.videos },
    }),
    '/:lang/samples/:id': Sample,
    // The catch-all route must always be last
    '*': NotFound
};
