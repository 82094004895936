<script>
  import { onMount } from "svelte";
  import { push } from "svelte-spa-router";

  export let languages = [];

  push("#/en_US"); // Remove for multi-language
  onMount(() => {
    document.body.classList.remove("body--inverted");
    document.body.classList.add("body--hide-nav");
  })
</script>

<div class="content content--home">
  <h1>Please choose your language</h1>
  <ul>
    {#each languages as language}
      <li><a href="#/{language.locale}">{language.name}</a></li>
    {/each}
  </ul>
</div>
