<script>
  import "./scss/main.scss";

  import Router, { push } from "svelte-spa-router";
  import routes from "./routes";

  import logo from "$assets/logo.svg";
  import Idle from "$lib/components/Idle.svelte";
  import { visible } from "$lib/video.store";

  const idle = { time: 0, state: false, wait: 30 };
  const clearTimeout = () => {
    idle.time = 0;
    idle.state = false;
  };

  setInterval(() => idle.time++, 1000);
  $: if (idle.time > idle.wait && !$visible) {
    idle.state = true;
    push("#/en_US");
  } else if ($visible) {
    clearTimeout();
  }
</script>

<svelte:window on:mousemove|keydown|scroll={clearTimeout} />
{#if idle.state}
  <Idle />
{/if}

<nav class="nav">
  <img src={logo} alt="Logo Salvagnini" />
</nav>
<div class="container">
  <Router {routes} />
</div>
