<script>
  import { onMount, onDestroy } from "svelte";
  import { _ } from "svelte-i18n";
  import { visible } from "$lib/video.store";

  export let id;

  let video, loops = 0;
  
  const getBack = () => {
    loops > 0 ? window.history.back() : video.play();
    loops++;
  };

  onMount(() => {
    visible.set(true);
  });

  onDestroy(() => {
    visible.set(false);
  });
</script>

<!-- svelte-ignore a11y-media-has-caption -->
<video bind:this={video} autoplay playsinline on:ended={getBack}>
  <source src={`videos/${id}.mp4`} type="video/mp4" />
  {$_("video_not_supported")}
</video>
